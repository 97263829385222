// src/components/Navbar.js
import React from "react";
import { Link } from "react-scroll";

const Navbar = () => {
  return (
    <nav className="bg-black-800 p-4 fixed w-full z-10">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-white text-1xl">Alexander Lasson</div>
        <div className="space-x-4">
          <Link
            to="home"
            smooth={true}
            duration={500}
            className="text-gray-300 hover:text-white cursor-pointer"
          >
            Home
          </Link>
          <Link
            to="about"
            smooth={true}
            duration={500}
            className="text-gray-300 hover:text-white cursor-pointer"
          >
            About
          </Link>
          <Link
            to="projects"
            smooth={true}
            duration={500}
            className="text-gray-300 hover:text-white cursor-pointer"
          >
            Projects
          </Link>
          <Link
            to="contact"
            smooth={true}
            duration={500}
            className="text-gray-300 hover:text-white cursor-pointer"
          >
            Contact
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
