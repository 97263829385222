import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const ContactSection = () => {
  const [form, setForm] = useState({
    userEmail: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send(
      'service_zyidoao', // Replace with your EmailJS service ID
      'template_xckrck5', // Replace with your EmailJS template ID
      {
        from_name: form.userEmail,
        to_name: 'Your Name', // Replace with the recipient name
        subject: form.subject,
        message: form.message,
      },
      'ylbqRvfZcWPdQER_f' // Replace with your EmailJS user ID
    )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      alert('Email sent successfully!');
    })
    .catch((err) => {
      console.error('FAILED...', err);
      alert('Failed to send email.');
    });
  };

  return (
    <section
      id="contact"
      className="h-screen flex items-center justify-center bg-black-950"
      style={{
        background: 'linear-gradient(to bottom, rgb(58, 6, 31), rgb(0, 0, 0))',
        color: "#ffffff",
        textAlign: "center",
        padding: "0 20px",
      }}
    >
      <div className="text-center p-6">
        <h1 className="text-4xl font-bold mb-4">Contact</h1>
        <p className="text-lg mb-8">
          Feel free to reach out to me via email or through my social media channels.
        </p>
        <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
          <div className="mb-4">
            <label className="block text-left mb-2" htmlFor="userEmail">Your Email</label>
            <input
              type="email"
              name="userEmail"
              value={form.userEmail}
              onChange={handleChange}
              required
              className="w-full p-2 border rounded-lg text-black"
            />
          </div>
          <div className="mb-4">
            <label className="block text-left mb-2" htmlFor="subject">Subject</label>
            <input
              type="text"
              name="subject"
              value={form.subject}
              onChange={handleChange}
              required
              className="w-full p-2 border rounded-lg text-black"
            />
          </div>
          <div className="mb-4">
            <label className="block text-left mb-2" htmlFor="message">Message</label>
            <textarea
              name="message"
              value={form.message}
              onChange={handleChange}
              required
              className="w-full p-2 border rounded-lg text-black"
            ></textarea>
          </div>
          <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg">
            Send
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactSection;
