import React, { useRef, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const Scene2 = () => {
  return (
    <Canvas>
      <ambientLight intensity={0.5} />
      <directionalLight position={[100, 10, 0]} intensity={50.8} />
      <GLTFModel />
    </Canvas>
  );
};

const GLTFModel = () => {
  const gltfRef = useRef();

  useEffect(() => {
    const loader = new GLTFLoader();
    loader.setPath("/firewatch_g/");
    loader.load("scene.gltf", (gltf) => {
      gltfRef.current.add(gltf.scene);

      // Set the initial position of the model here
      gltfRef.current.rotation.set(50, 100, 0); // Rotates 180 degrees on Y-axis
      gltfRef.current.position.set(0, -50, -150);

      // Animate the position change
      const initialPosition = { x: 50, y: -55, z: -145 }; // Adjust the target position as needed
      const targetPosition = { x: 0, y: -250, z: -250 };
      const duration = 15000; // Duration of the animation in milliseconds

      const animatePosition = (startTime) => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - startTime;
        const progress = (elapsedTime % duration) / duration; // Calculate progress in the loop

        const newPosition = {
          x: initialPosition.x + (targetPosition.x - initialPosition.x) * progress,
          y: initialPosition.y + (targetPosition.y - initialPosition.y) * progress,
          z: initialPosition.z + (targetPosition.z - initialPosition.z) * progress
        };
        gltfRef.current.position.set(newPosition.x, newPosition.y, newPosition.z);
        requestAnimationFrame(() => animatePosition(startTime));
      };

      animatePosition(Date.now()); // Start the animation loop
    });
  }, []);

  return <group ref={gltfRef} className="model-initial" />;
};

export default Scene2;
