// src/App.js
import React from "react";
import Navbar from "./components/Navbar";
import Scene from "./components/Scene";
import Scene2 from "./components/Scene2";
import ContactSection from "./components/email";

import "./styles/tailwind.css";
import "./App.js";
import "./styles/typewriter.css";
import "./styles/fadein.css";
import '@fortawesome/fontawesome-free/css/all.css';



const App = () => {
  return (
    <div className="h-screen w-screen dark:bg-black dark:text-white">
      <Navbar />
      <section id="home" className="h-screen flex items-center justify-center" style={{ background: 'linear-gradient(to bottom, rgba(220, 128, 180, 0.8) 0%, rgba(255, 220, 255, 0.99) 40%, rgba(105, 50, 0, 0.3) 100%)' }}>
        <Scene className='fadein' />
        <h1
          className="text-4xl md:text-6xl font-bold text-white absolute fade-in"
          style={{
            top: '18%', // Adjust vertical position
            left: '30%', // Adjust horizontal position
            transform: 'translate(-50%, -50%)', // Center the element
            position: 'absolute',
            maxWidth: '80%', // Adjust maximum width
            textAlign: 'center' // Center the text horizontally
          }}
        >
          Development Portfolio
        </h1>
      </section>








      <section
        id="about"
        className="h-screen flex flex-col lg:flex-row items-center justify-center"
        style={{
          background: 'linear-gradient(to bottom, rgba(70, 9, 31, 0.9), rgba(255, 228, 196, .8)), url("/background.jpg") no-repeat center center/cover',
          color: "#ffffff",
          padding: "0 20px",
        }}
      >
        <div className="max-w-4xl mx-auto flex flex-col items-center lg:items-start lg:w-1/2">
          <img src="/profile.jpg" alt="Profile" className="rounded-full w-48 h-48 mr-auto mb-6 sm:block hidden" />

          <h1 className="text-4xl lg:text-5xl font-bold mb-4">Hello, I'm Alexander</h1>
          <h2 className="text-2xl lg:text-3xl mb-4">Software Engineer | Web Developer | Designer</h2>
          <p className="text-center lg:text-left text-lg lg:text-xl mb-6">
            I'm a passionate software developer with over 3 years of experience in creating dynamic and responsive applications. My goal is to build seamless and user-friendly software that provide a great user experience.
            Bilingual in English/Swedish.
          </p>
          <div className="flex flex-wrap justify-center lg:justify-start mb-6">
            <span className="bg-gray-800 text-white rounded-full px-4 py-2 m-2">Python</span>
            <span className="bg-gray-800 text-white rounded-full px-4 py-2 m-2">Django</span>
            <span className="bg-gray-800 text-white rounded-full px-4 py-2 m-2">Rust</span>
            <span className="bg-gray-800 text-white rounded-full px-4 py-2 m-2">JavaScript</span>
            <span className="bg-gray-800 text-white rounded-full px-4 py-2 m-2">React</span>
            <span className="bg-gray-800 text-white rounded-full px-4 py-2 m-2">REST APIs</span>
            <span className="bg-gray-800 text-white rounded-full px-4 py-2 m-2">RDBMS</span>
            <span className="bg-gray-800 text-white rounded-full px-4 py-2 m-2">GIT/JIRA</span>

          </div>
          <div className="flex justify-center lg:justify-start mb-6">
            <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer" className="mx-2 text-3xl text-white hover:text-gray-500">
              <i className="fab fa-facebook-square"></i>
            </a>
            <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer" className="mx-2 text-3xl text-white hover:text-gray-500">
              <i className="fab fa-instagram-square"></i>
            </a>
            <a href="mailto:lassonalexander@gmail.com" target="_blank" rel="noopener noreferrer" className="mx-2 text-3xl text-white hover:text-gray-500">
              <i className="far fa-envelope"></i>
            </a>
            <a href="https://github.com/AlexanderLasson" target="_blank" rel="noopener noreferrer" className="mx-2 text-3xl text-white hover:text-gray-500">
              <i className="fab fa-github-square"></i>
            </a>
            <a href="https://www.linkedin.com/in/alexander-lasson/" target="_blank" rel="noopener noreferrer" className="mx-2 text-3xl text-white hover:text-gray-500">
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg mb-6">
            <p className="text-lg text-center">"The only limit to our realization of tomorrow will be the doubts of today"</p>
            <p className="text-right mt-2">- Roosevelt</p>
          </div>

        </div>
      </section>





      <section
        id="projects"
        className="h-screen flex items-center justify-center relative"
        style={{ background: 'linear-gradient(to bottom, rgba(25, 228, 196, .8) 0%, rgba(238, 251, 238, 0.9) 40%, rgba(76, 0, 130, 0.3) 100%)' }}

      >
        <Scene2 />

        <div className="text-center p-6 absolute top-0 left-0 right-0 bg- bg-opacity-0"> {/* Semi-transparent background */}
          <h1 className="text-4xl font-bold mb-4 text-white">Projects</h1>
          <p className="text-lg text-white mb-8">
            Here are some of the projects I've worked on, including this webpage built in React.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Example project card with background image */}
            <div className="relative rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 overflow-hidden">
              <div className="absolute inset-0 bg-cover bg-center" style={{ backgroundImage: 'url(/junibo.gif)' }}>
                <div className="absolute inset-0 bg-black bg-opacity-70"></div>
              </div>
              <div className="relative p-4">
                <h2 className="text-2xl font-bold mb-2 text-white">🏪Junibo</h2>
                <p className="text-gray-200 mb-4">A micro digital e-commerce site featuring social authentication, Stripe API integration, Node.js/Tailwind, administrative sales analytics</p>
                <a href="https://junibo.shop" className="text-blue-400 hover:text-blue-600">View Site</a>
              </div>
            </div>

            {/* Repeat similar blocks for other projects */}
            <div className="relative rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 overflow-hidden">
  <div className="absolute inset-0 bg-cover bg-center" style={{ backgroundImage: 'url(/LegalLite.gif)' }}>
    <div className="absolute inset-0 bg-black bg-opacity-50"></div>
  </div>
  <div className="relative p-4">
    <h2 className="text-2xl font-bold mb-2 text-white">📜LegalLite</h2>
    <p className="text-gray-200 mb-4">Built a Terms of Service agreement scanner that scans for validity.  Integrated spaCy for automated annotation of TOS submissions, enhancing the efficiency of identifying specific legal clauses.</p>
    <a href="https://github.com/AlexanderLasson/LegalLite" className="text-blue-400 hover:text-blue-600">View Project on Github</a>
  </div>
</div>
<div className="relative rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 overflow-hidden">
  <div className="absolute inset-0 bg-cover bg-center" style={{ backgroundImage: 'url(/HLS.gif)' }}>
    <div className="absolute inset-0 bg-black bg-opacity-70"></div>
  </div>
  <div className="relative p-4">
    <h2 className="text-2xl font-bold mb-2 text-white">📖LinkJunction</h2>
    <p className="text-gray-200 mb-4">A social bookmark web application with REST api, email authentication, token based collaboration, ajax integration, Chrome bookmark importing. Implemented Social/CRUD features with a search design reminiscent of Google's.</p>
    <a href="" className="text-blue-400 hover:text-blue-600">Deploying for Client Soon! (Freelance Project)</a>
  </div>
</div>
<div className="relative rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 overflow-hidden sm:block hidden">
  <div className="absolute inset-0 bg-cover bg-center" style={{ backgroundImage: 'url()' }}>
    <div className="absolute inset-0 bg-black bg-opacity-50"></div>
  </div>
  <div className="relative p-4">
    <h2 className="text-2xl font-bold mb-2 text-white">Web Socket</h2>
    <p className="text-gray-200 mb-4">Engineered a scalable Web socket for a chat client in Rust 🦀</p>
    <a href="#" className="text-blue-400 hover:text-blue-600">View on Github</a>
  </div>
</div>






          </div>
        </div>
      </section>




      <section
        id="contact"
        className="min-h-screen flex items-center justify-center bg-black-950"
        style={{
          background: 'linear-gradient(to bottom, rgb(58, 6, 31), rgb(0, 0, 0)',
          color: "#ffffff",
          textAlign: "center",
          padding: "0 20px", // Adjust padding as needed
        }}
      >

        {/* <Scene/> */}
        <div className="text-center p-6">

          <ContactSection />

        </div>
      </section>
    </div>






  );
};

export default App;
